import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./registration.css";
import { Helmet } from "react-helmet";
import axios from "axios";

function getCookie(name) {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  return cookie ? decodeURIComponent(cookie.split("=")[1]) : null;
}

const RegistrationForm = () => {
  const initialFormState = {
    pet_category: "",
    pet_name: "",
    owner_name: "",
    owner_email: "",
    owner_phone: "",
    owner_address: "",
    breed: "",
    passport_vaccine: null,
    special_needs: "",
    vaccination_certificate: false,
    category_id: null,
  };

  const [formData, setFormData] = useState(initialFormState);
  const [petCategories, setPetCategories] = useState([]);
  const [checkboxWarning, setCheckboxWarning] = useState(false);

  useEffect(() => {
    axios
      .get("https://admin.petparentsfest.com/api/category/")
      .then((response) => {
        console.log("Pet Categories fetched:", response.data);
        setPetCategories(response.data);

        const defaultCategory = response.data.find(
          (category) => category.name.toLowerCase() === "dog"
        );
        if (defaultCategory) {
          setFormData((prevData) => ({
            ...prevData,
            pet_category: defaultCategory.id,
            category_id: defaultCategory.id,
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching pet categories:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "vaccination_certificate") {
      setCheckboxWarning(false); // Reset warning when checkbox is interacted with
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "pet_category") {
      const selectedCategory = petCategories.find(
        (category) => category.id === parseInt(value)
      );
      if (selectedCategory) {
        setFormData((prevData) => ({
          ...prevData,
          category_id: selectedCategory.id,
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.vaccination_certificate) {
      setCheckboxWarning(true); // Activate warning state
     
      return;
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== undefined) {
        data.append(key, formData[key]);
      }
    });

    fetch("https://admin.petparentsfest.com/api/pet-registration/", {
      method: "POST",
      body: data,
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.success) {
          toast.success("Registration submitted successfully!");
          setFormData(initialFormState);
        } else {
          toast.error("Submission failed. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error during submission:", error);
        toast.error("Form submission failed. Please try again.");
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Register for the Top Pet Parents Fest 2025 by WonderMom</title>
        <meta
          name="description"
          content="Join the Pet Parents Fest 2025! Register your pets for fun activities and expert tips in pet care and training for all pet lovers."
        />
      </Helmet>

      <section
        className="section"
        id="reservation"
        style={{ backgroundImage: "url(/images/reg.webp)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self">
              <div className="left-text-content">
                <div className="section-heading">
                  <h6>Pet Parents Fest 2025 by wondermom</h6>
                  <h2>Register your Pet for the event!</h2>
                </div>
                <p style={{ marginBottom: "15px" }}>
                  Join us for an unforgettable experience at the Pet Parents
                  Fest 2025, taking place on January 11th & 12th, 2025! Secure
                  your spot today and be part of a community that celebrates the
                  joy of pets.
                </p>
                <p style={{ marginBottom: "30px" }}>
                  For any questions or assistance regarding registration, feel
                  free to reach out to us at +971 54 508 3789 or{" "}
                  <a
                    href="https://wa.me/+971569064547"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    click here
                  </a>{" "}
                  to WhatsApp us.
                </p>
                <p style={{ lineHeight: "30px" }}>
                  <b>
                    <u>Event Details</u>
                  </b>
                  <br />
                  Dates: January 11th & 12th, 2025!
                  <br />
                  Venue: Open Cricket Pitch , Damac Hills 1,Dubai
                </p>
                <div className="terms-conditions">
                  <div className="scrollable-box">
                    <h2 className="section-heading">Terms & Conditions</h2>
                    <ul>
 <li>Dog-only entry is permitted at the event.</li>
      <li>Entry is completely free for dogs and their families.</li>
      <li>
        All dogs must have proof of vaccination, a pet passport, and a microchip.
        These are mandatory for entry.
      </li>
      <li>
        All participating dogs should enter through the designated entrance at
        the venue.
      </li>
      <li>
        All dogs must pass through the Dog Assessment Area and collect
        color-coded ribbons issued by the assessment team.
      </li>
      <li>
        Dogs must always be on a leash and wear a mask. Extendable leashes are
        not permitted in the venue.
      </li>
                    </ul>
                    <h2>Undertaking</h2>
                    <p>
                      Pet owners accept full responsibility for their pets and
                      will compensate for any damages caused by their pets. The
                      owners bring their animals at their own risk. The
                      organizer or officials will not be responsible for the
                      actions of the pets.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <div
                className="contact-form"
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="row" style={{ justifyContent: "left" }}>
                    <div className="col-lg-12">
                      <h4 className="reg-title">PET REGISTRATION</h4>
                    </div>

                    {/* Pet Category Dropdown */}
                    <div className="col-lg-6 col-sm-12">
                      <select
                        name="pet_category"
                        value={formData.pet_category}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Pet Category *</option>
                        {petCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        name="pet_name"
                        type="text"
                        value={formData.pet_name}
                        onChange={handleChange}
                        placeholder="Name of the Pet *"
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <input
                        name="owner_name"
                        type="text"
                        value={formData.owner_name}
                        onChange={handleChange}
                        placeholder="Name of Registered Owner *"
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <input
                        name="owner_email"
                        type="email"
                        value={formData.owner_email}
                        onChange={handleChange}
                        placeholder="Email *"
                      />
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <input
                        name="owner_phone"
                        type="text"
                        value={formData.owner_phone}
                        onChange={handleChange}
                        placeholder="Phone Number *"
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <input
                        name="breed"
                        type="text"
                        value={formData.breed}
                        onChange={handleChange}
                        placeholder="Breed *"
                        required
                      />
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <input
                        name="owner_address"
                        type="text"
                        value={formData.owner_address}
                        onChange={handleChange}
                        placeholder="Address *"
                        required
                      />
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <textarea
                        name="special_needs"
                        type="text"
                        value={formData.special_needs}
                        onChange={handleChange}
                        placeholder="Special Needs (if any)"
                      />
                    </div>

                    <div
                      className="custom-checkbox" 
                      style={{ padding: "15px", textAlign: "left"  }}
                    >
                      <input
                        type="checkbox"
                        id="vaccination_certificate"
                        name="vaccination_certificate"
                        checked={formData.vaccination_certificate}
                        onChange={handleChange}
                        className="custom-checkbox-input"
                      />
                      <label
                        htmlFor="vaccination_certificate"
                        className="custom-checkbox-label"
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "#555",
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                      >
                        Bringing the vaccination certificate to the event,
                        either in physical or electronic copy, is compulsory.
                      </label>
                      {checkboxWarning && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "5px",
                            display: "block",
                          }}
                        >
                          You must agree to bring the vaccination certificate.
                        </span>
                      )}
                    </div>

                    <div className="col-lg-12 mt-4">
                      <button
                        type="submit"
                      >
                        Register Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RegistrationForm;
