import React from 'react';
import './AboutUs.css'
const AboutUs = () => {
  return (
    <section className="section" id="about" style={{padding: "10px 0px 0px"}}>
      <div className="container">
        <div className="row">
          {/* WONDERMOM BUMP TO BABY Section */}
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="left-text-content">
              <div className="section-heading">
                <h6>ABOUT US</h6>
                <h2 style={{marginBottom:'0px'}}>PET PARENTS FEST BY WONDERMOM</h2>
              </div>
             <p>
  <p>
    Welcome to the <strong>Pet Parents Fest</strong>, the ultimate celebration of the love and joy dogs bring into our lives!
    Organized by WonderMom, this event is open to all animal enthusiasts, families, and individuals who want to celebrate the special bond between humans and pets.
    Please note, however, that only dogs are allowed as pets at the event.
  </p>
  <p>
    Taking place on <strong>January 11th and 12th, 2025</strong>, the <strong>Pet Parents Fest</strong> offers a two-day experience filled with exciting activities,
    live demonstrations, and interactive events for dog lovers of all ages. Whether you're looking to learn more about dog care,
    explore the latest trends in dog health, training, and fashion, or simply enjoy a day of dog-related fun, this event has something for everyone.
  </p>
</p>

            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="right-content">
              <div className="thumb">
                <img src="/images/main.webp" alt="The WonderMoM Fest" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
